import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const usePasswordVisibilityToggle = (set, state) => {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onMouseDown={() => set(!state)}
                onMouseUp={() => set(!state)}
                edge="end"
            >
                {state ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    )
}

export default usePasswordVisibilityToggle;