import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTextfield = styled(TextField)({
    '& label.Mui-focused': {
        color: '#4EBAB8',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#4EBAB8',
    },
    '& .MuiOutlinedInput-root': {
        fontSize: '2rem',
        fontFamily: 'Sen',
        color: '#162032',
        width: '39.55rem',
        '& fieldset': {
            width: '39.55rem',
            borderColor: '#4EBAB8',
            borderRadius: 0,
            fontSize: '2rem'
        },
        '&:hover fieldset': {
            borderColor: '#4EBAB8',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EBAB8',
        },
    },
});