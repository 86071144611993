import { useState, useEffect } from 'react';

import { useForm } from "react-hook-form";
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Img from 'react-cool-img';

import Api from '../../services/api';
import useSnackbarHook from '../hooks/useSnackbarHook';
import useFormValidationRules from '../hooks/useFormValidationRules';
import { useAppContext } from '../../context/state';
import { CustomTextfield } from '../customMuiTextfield';
import { categories } from '../../constants/categories';
import { subcategories } from '../../constants/subcategories';

export default function StartupForm(props = {}) {
    const [currentStartup, setCurrentStartup] = useState({});
    const [filteredSubcategories, setFilteredSubcategories] = useState([]);
    const context = useAppContext();
    const { t } = useTranslation();
    const { validationRules, descriptionMaxLength } = useFormValidationRules();
    const investmentRoundTypes = ['Pre Seed', 'Seed', 'Series A', 'Series B', 'Series C', 'Series D'];
    const formDefaultValues = {
        name: currentStartup.name || '',
        url: currentStartup.url || '',
        category: currentStartup.category || '',
        subcategory: currentStartup.subcategory || '',
        image: currentStartup.image || '',
        description: currentStartup.description || '',
        foundationYear: currentStartup.foundationYear || '',
        lastRound: currentStartup.lastRound || '',
        yearRound: currentStartup.yearRound || '',
        totalRound: currentStartup.totalRound || '',
        leadInvestor: currentStartup.leadInvestor || '',
        otherInvestor: currentStartup.otherInvestor || '',
        contactName: currentStartup.contactName || '',
        position: currentStartup.position || ''
    }
    const { register, watch, formState: { errors }, handleSubmit, control, reset, setValue } = useForm({
        defaultValues: { ...formDefaultValues }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: ('founders')
    });
    const { Alert, alertState, setAlertState, handleError } = useSnackbarHook();
    const watchCategory = watch('category', formDefaultValues.category);
    const watchSubcategory = watch('subcategory', formDefaultValues.subcategory);
    const watchDescription = watch('description', formDefaultValues.description);
    const watchFoundationYear = watch('foundationYear', formDefaultValues.foundationYear);
    const watchYearRound = watch('yearRound', formDefaultValues.yearRound);
    const watchLastRound = watch('lastRound', formDefaultValues.lastRound);
    const watchImage = watch('image', formDefaultValues.image);

    useEffect(() => {
        if (!Object.keys(currentStartup).length) {
            setCurrentStartup({ ...props.startup });
        }
    }, [props.startup]);

    useEffect(() => {
        if (props.apiError) {
            handleError(props.apiError)
        }
    }, [props.apiError]);

    useEffect(() => {
        reset({ ...formDefaultValues })
        if (currentStartup.founders) {
            currentStartup.founders.map((founder) => append(founder));
        }
    }, [currentStartup]);

    useEffect(() => {
        if (watchCategory !== '') {
            const currentCategory = categories.filter(category => { return category.name === watchCategory })[0];
            const newSubcategories = subcategories.filter(subcategory => { return subcategory.category === currentCategory?.id });
            setFilteredSubcategories(newSubcategories);
        }
    }, [watchCategory]);

    function getYearRange() {
        const currentYear = new Date().getFullYear()
        const minYear = 2000;
        const years = []

        for (let i = currentYear; i >= minYear; i--) {
            years.push(i);
        }
        return years;
    }

    const yearOptions = getYearRange();

    async function uploadChanges(startupData) {
        const idToken = localStorage.getItem('idToken');
        const userId = localStorage.getItem('userId');
        if (props.startup) {
            const normalizedStartupName = startupData.name.trim().toLowerCase();
            const editedStartup = {
                ...startupData,
                id: currentStartup.id,
                contactId: userId,
                contactEmail: context.sharedState.credentials?.email,
                description: startupData.description.replaceAll('"', "'"),
                name: normalizedStartupName,
            };
            const [response, apiError] = await Api.patchStartup(editedStartup, idToken);
            if (apiError) {
                handleError(apiError);
                return false;
            }
            setAlertState({ message: t('forms.successful_edit'), severity: 'success', showAlert: true });
        } else {
            props.handleStartupRegistration && props.handleStartupRegistration(startupData);
        }
    }

    return (
        <>
            <form className='boxed-form big' onSubmit={handleSubmit(uploadChanges)}>
                <CustomTextfield id='contactName' label={t('forms.labels.contact_name')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} required error={(errors.contactName) ? true : false}
                    helperText={errors?.contactName?.message}
                    {...register('contactName', { required: { ...validationRules.requiredField } })}
                />
                <CustomTextfield id='position' label={t('forms.labels.position')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} required error={(errors.position) ? true : false}
                    helperText={errors?.position?.message}
                    {...register('position', { required: { ...validationRules.requiredField } })}
                />

                <fieldset className='form-fieldset fieldset-border'>
                    <p className='fieldset-title'>{t('forms.labels.startup_profile')}</p>
                    <CustomTextfield id='startupName' label={t('forms.labels.startup')} InputLabelProps={{ shrink: true, className: 'custom-label' }} required error={(errors.name) ? true : false}
                        helperText={errors?.name?.message}
                        {...register('name', { required: { ...validationRules.requiredField } })}
                    />
                    <CustomTextfield id='url' label={t('forms.labels.website')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} required error={(errors.url) ? true : false}
                        helperText={errors?.url?.message} placeholder={t('forms.placeholders.url')}
                        {...register('url', { required: { ...validationRules.requiredField }, pattern: { ...validationRules.url } })}
                    />
                    <CustomTextfield
                        sx={{ widht: 100 }}
                        value={watchCategory}
                        select
                        label={t('forms.labels.category')}
                        onClick={() => {
                            setValue('subcategory', '')
                        }}
                        required
                        {...register('category', { required: { ...validationRules.requiredField } })}
                        InputLabelProps={{ shrink: true, className: 'custom-label' }}
                    >
                        {categories.map(category => <MenuItem value={category.name} key={category.id} sx={{ fontSize: '1.6rem' }}>{category.name}</MenuItem>)}
                    </CustomTextfield>
                    {watchCategory !== ''
                        ? <CustomTextfield
                            sx={{ widht: 100 }}
                            value={watchSubcategory}
                            select
                            label={t('forms.labels.subcategory')}
                            required
                            {...register('subcategory', { required: { ...validationRules.requiredField } })}
                            InputLabelProps={{ shrink: true, className: 'custom-label' }}
                        >
                            {filteredSubcategories.map(subcategory => <MenuItem value={subcategory.name} key={subcategory.id} sx={{ fontSize: '1.6rem' }}>{subcategory.name}</MenuItem>)}
                        </CustomTextfield>
                        : <></>}
                    <CustomTextfield id='imageUrl' label={t('forms.labels.image')} type='text' error={(errors.image) ? true : false}
                        helperText={errors?.image?.message} InputLabelProps={{ shrink: true, className: 'custom-label' }}
                        {...register('image', { pattern: { ...validationRules.url } })} placeholder={t('forms.placeholders.image')}
                    />
                    {watchImage !== '' && <Img className='image-url-preview' alt='' src={watchImage} placeholder={'/png/placeholder.png'} error={'/png/placeholder.png'} />}
                    <CustomTextfield id='description' label={t('forms.labels.description')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }}
                        {...register('description', { required: { ...validationRules.requiredField }, maxLength: { ...validationRules.startupDescriptionLength } })} error={(errors.description) ? true : false}
                        multiline rows={7} inputProps={{ maxLength: descriptionMaxLength }} required helperText={errors?.description?.message}
                    />
                    <sub className='character-counter'>{watchDescription.length}/{descriptionMaxLength}</sub>
                </fieldset>

                <fieldset className='form-fieldset fieldset-border'>
                    <p className='fieldset-title'>{t('forms.labels.founders')}</p>
                    {fields.map(({ id, name, url }, index) => {
                        return (
                            <div className='founder-inputs-wrapper' key={id}>
                                <CustomTextfield
                                    id='founderName' label={t('forms.labels.founder_name')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} required
                                    {...register(`founders[${index}].name`, { required: { ...validationRules.requiredField } })} helperText={errors.founders?.[index]?.name?.message}
                                    error={errors.founders?.[index]?.name} name={`founders[${index}].name`}
                                    defaultValue={name}
                                />
                                <CustomTextfield
                                    id='founderLinkedin' label={t('forms.labels.founder_LinkedIn')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} error={errors.founders?.[index]?.url}
                                    helperText={errors.founders?.[index]?.url?.message} required
                                    {...register(`founders[${index}].url`, { pattern: { ...validationRules.url }, required: { ...validationRules.requiredField } })}
                                    name={`founders[${index}].url`}
                                    defaultValue={url}
                                />
                                <button className='form-button' type="button" onClick={() => remove(index)}>
                                    {t('forms.remove_founder')}
                                </button>
                            </div>
                        );
                    })}
                    <button type='button' className='form-button' onClick={() => append({})}>
                        {t('forms.add_founder')}
                    </button>
                    <CustomTextfield
                        id='foundationYear'
                        sx={{ widht: 100 }}
                        value={watchFoundationYear}
                        select
                        label={t('forms.labels.foundation_year')}
                        required
                        error={errors.foundationYear ? true : false}
                        helperText={errors?.foundationYear?.message}
                        {...register('foundationYear', { required: { ...validationRules.requiredField }, min: { value: validationRules.year.min, message: validationRules.year.message }, max: validationRules.year.max })}
                        InputLabelProps={{ shrink: true, className: 'custom-label' }}
                    >
                        {yearOptions.map(year => <MenuItem value={year.toString()} key={year} sx={{ fontSize: '1.6rem' }}>{year}</MenuItem>)}
                    </CustomTextfield>
                </fieldset>

                <fieldset className='form-fieldset fieldset-border'>
                    <p className='fieldset-title'>{t('forms.labels.investment_round')}</p>
                    <CustomTextfield
                        id='lasInvestmentRound'
                        sx={{ widht: 100 }}
                        value={watchLastRound}
                        select
                        label={t('forms.labels.investment_round')}
                        {...register('lastRound')}
                        InputLabelProps={{ shrink: true, className: 'custom-label' }}
                    >
                        {investmentRoundTypes.map(round => <MenuItem value={round} key={round} sx={{ fontSize: '1.6rem' }}>{round}</MenuItem>)}
                    </CustomTextfield>
                    <CustomTextfield
                        id='yearRound'
                        sx={{ widht: 100 }}
                        value={watchYearRound}
                        select
                        label={t('forms.labels.round_year')}
                        error={errors.yearRound ? true : false}
                        helperText={errors?.yearRound?.message}
                        {...register('yearRound', { min: { value: validationRules.year.min, message: validationRules.year.message }, max: validationRules.year.max })}
                        InputLabelProps={{ shrink: true, className: 'custom-label' }}
                    >
                        {yearOptions.map(year => <MenuItem value={year.toString()} key={yearOptions.indexOf(year)} sx={{ fontSize: '1.6rem' }}>{year}</MenuItem>)}
                    </CustomTextfield>
                    <CustomTextfield id='totalRound' label={t('forms.labels.total_round')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} error={(errors.totalRound) ? true : false}
                        helperText={errors?.totalRound?.message}
                        {...register('totalRound')}
                    />
                    <CustomTextfield id='leadInvestor' label={t('forms.labels.lead_investor')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} error={(errors.leadInvestor) ? true : false}
                        helperText={errors?.leadInvestor?.message}
                        {...register('leadInvestor')}
                    />
                    <CustomTextfield id='otherInvestor' label={t('forms.labels.other_investor')} type='text' InputLabelProps={{ shrink: true, className: 'custom-label' }} error={(errors.otherInvestor) ? true : false}
                        helperText={errors?.otherInvestor?.message}
                        {...register('otherInvestor')}
                    />
                </fieldset>
                <button type='submit' className='submit-button'>{
                    props.isProfile ? t('actions.save_changes') : t('registration.register')
                }</button>
            </form>
            <Snackbar open={alertState.showAlert} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, showAlert: false })} >
                <Alert severity={alertState.severity} sx={{ width: '100%', fontSize: '2rem' }}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </>
    )
}